.centered {

text-align: center;

}

.aboutText {


    font-size: 1.25rem;

}

.ui.header>.icon {
    display: table-cell;
    opacity: 1;
    font-size: 1.5em;
    padding-top: 0;
    vertical-align: middle;
    color: rgb(0, 0, 0);
}

.ui.header>.icon+.content {
    padding-left: .75rem;
    display: table-cell;
    vertical-align: middle;
    color: rgb(0, 0, 0);
    
}


.container.frame.tl-radius.tr-radius.fb-padding {
    text-align: center!important;
}

@media screen and (max-width: 350px) {

    .container.frame.tl-radius.tr-radius.fb-padding {
        text-align: center!important;
        font-size: 0.8em;
    }

}