.bg-light{

    border-radius: 25px;
}

.bg-info2 {
    
    background-color: rgba(var(--bs-info-rgb),0)!important;
}

.card {
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 0vw;
    max-width: 20vw;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none !important;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    }

    .truncate {
        height: 10vh;
        display: flex;
        flex-wrap: nowrap;
        align-content: space-between;
        justify-content: space-evenly;
        align-items: flex-end;
        flex-direction: row;
        font-size: min(max(16px, 12vw), 20px);
        font-weight: 450;
    }

    div.card-body {
        background-color: #bddaff47;
        min-width: 150px!important;
        height: 200px;
    }

@media screen and (max-width: 660px) {
    .card {
        margin-left: 16vw !important;
    }
  }



.testimonial{

    font-family: 'arial';
font-size: 1.4rem;
}

.avatar {

width: 50px;
height: 50px;
}

.quote-top {
    position: relative;
    transform: rotate(180deg);
    margin-bottom: 1em;
    left: -5px;
}

.quote-bottom {

margin-top: 1em;
margin-left: 4px;
}

