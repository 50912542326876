.main-footer {
     
    background-color: #030800;
    color: white;
    width: 100vw !important;
    max-width: 100vw !important;
  }

  .col {
    
    flex: 1 0;
    min-height: 20vh;
    flex: 1 0;
    text-align: center;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
}




.i.icon {

  margin: 0 2.45rem 0 0;

}

i.icon.arrow.alternate.circle.up.outline:before {
  content: "\f35b";
  position: relative !important;
  right: 40px !important;
  bottom: 20px !important;

}



.copywrite {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  margin-left: 2em !important;
}

.toTop {
  display: flex;
  margin-right: 10px!important;
  justify-content: flex-end;
  flex-direction: row;
  margin-bottom: 20px;
}