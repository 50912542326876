.Logo {
    pointer-events: none;
    min-height: 200px;
    max-height: 27vh;        
    top: 5vh;
    
}

div.header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: #73d2ea;
    padding-top: 8px;
}