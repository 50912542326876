/* Icon when the collapsible content is shown */
.btn:after {
    font-family: "Glyphicons Halflings";
    content: "\e114";
    float: right;
    margin-left: 15px;
  }
  /* Icon when the collapsible content is hidden */
  .btn.collapsed:after {
    content: "\e080";
  }

  .center {
text-align: center;
padding-top: 20px;
margin-bottom: -4rem;
color: rgb(0, 0, 0);
background-color: #73d2ea;
  }

  h2.center {
   
    position: relative;
    bottom: -2em;
}

  .faq-content {
    
    color: black;
    margin-top: 3vh;
}

.accordion-body {
  padding: 1rem 1.25rem;
  font-size: 1.2rem;
}

.accordion-button {

  font-size: 1.2rem;
}

  div.ui.breadcrumb {

    padding: 8px 8px!important;
    list-style: none;
    background-color: #73d2ea;
    margin-bottom: -1rem!important;
    justify-content: flex-start;
    
}

.breadcrumb {
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: -1.5rem;
}

.ui.breadcrumb .active.section {
  font-weight: 700;
  color: black;
}

