div.container-fluid{

    background-color: #73d2ea !important;
    box-shadow: 2px 2px #88888815;
text-align: center;

}

.infoBar {
    position: relative;
    margin: auto;
    max-height: 15vh;
    min-height: 50px;
    font-size: min(max(10px, 2vw), 20px);
    display: inline-flex;
    min-width: calc(50vw + 5vh);
    max-width: calc(75vw + 5vh);
    justify-content: space-evenly;
    align-items: center;
}



div.divider {
    color: rgba(0, 0, 255, 0.2);
    margin: 10px 10px;
}


@media screen and (max-width: 720px) {

    .infoBar {
        position: relative;
        margin: auto;
        max-height: 15vh;
        min-height: 50px;
        font-size: calc(16px + 0.5vw);
        display: inline-flex;
        min-width: calc(50vw + 5vh);
        max-width: calc(75vw + 5vh);
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        font-weight: 400;
    }

    div.container-fluid {
        background-color: #73d2ea !important;
        box-shadow: 2px 2px #88888815;
        text-align: center;
        min-height: calc(12em + 4px);
        padding-top: 8px;
    }

    div.divider {
        
        display: none;
    }

}