.css-e6zfwc {
    min-height: 0;
    padding-left: 0.6em;
}

.backgroundcolor {
    background: rgb(98,164,196);
    background: -moz-linear-gradient(0deg, rgba(98,164,196,1) 0%, rgba(94,174,194,1) 25%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(98,164,196,1) 0%, rgba(94,174,194,1) 25%, rgba(255,255,255,1) 100%);
    background: linear-gradient(0deg, rgba(98,164,196,1) 0%, rgba(94,174,194,1) 25%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#62a4c4",endColorstr="#ffffff",GradientType=1);
    }   


    @media only screen and (orientation: landscape){

        .images { 
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            
        }
    
    }

    @media only screen and (orientation: portrait){

    .images { 
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        max-height: 60vh;
    }

}