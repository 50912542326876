li {

list-style-type: none;
margin: 0.5em !important;

}

.align-items-center {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
}