

@media screen and (orientation: portrait){

  html {
  font-size: 14px;
  width: 100vw;
  max-width: 100.01vw !important;
  overflow-x: hidden !important;
}
}

@media screen and (orientation: landscape){
  
  html {
    font-size: 14px;
    width: 100vw;
    max-width: 100.01vw !important;
    overflow-x: hidden !important;
}
}


body {

  position: absolute!important;
  left: 0px !important;
  width: 100vw !important;
  margin: 0;
 

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(28,30,31);
  background: -moz-linear-gradient(0deg, rgba(28,30,31,1) 0%, rgba(23,93,110,1) 39%, rgba(74,126,153,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(28,30,31,1) 0%, rgba(23,93,110,1) 39%, rgba(74,126,153,1) 100%);
  background: linear-gradient(0deg, rgba(28,30,31,1) 0%, rgba(23,93,110,1) 39%, rgba(74,126,153,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1c1e1f",endColorstr="#4a7e99",GradientType=1);


}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

