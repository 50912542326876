.vstack {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch;
    margin-right: 9vw;
    align-items: flex-start;
}

.carousel-control-prev {
    left: 50px !important;
}

.carousel-grid {
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-content: center;
    flex-direction: column;
}

div.grid-left {
    min-width: 90vw;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin: auto;
    padding-bottom: 20px;
    max-width: 88vw!important;
}

.grid-right {

    padding: 20px !important;
    background: rgb(110,202,227);
    /* background: -moz-linear-gradient(0deg, rgba(91,181,203,1) 0%, rgba(110,203,227,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(91,181,203,1) 0%, rgba(110,203,227,1) 100%);
    background: linear-gradient(0deg, rgba(91,181,203,1) 0%, rgba(110,203,227,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5bb5cb",endColorstr="#6ecbe3",GradientType=1); */


}



.masonry {

    
    background: rgb(98,164,196) !important;
    background: -moz-linear-gradient(0deg, rgba(98,164,196,1) 0%, rgba(94,174,194,1) 25%, rgba(255,255,255,1) 100%)!important;
    background: -webkit-linear-gradient(0deg, rgba(98,164,196,1) 0%, rgba(94,174,194,1) 25%, rgba(255,255,255,1) 100%)!important;
    background: linear-gradient(0deg, rgba(98,164,196,1) 0%, rgba(94,174,194,1) 25%, rgba(255,255,255,1) 100%)!important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#62a4c4",endColorstr="#ffffff",GradientType=1)!important;

}

.container.frame.tl-radius.tr-radius.fb-padding{

    border-radius: 0;
}

.customStyle {
    color: "#f6fbfd";
   
    backGround: rgb(98,164,196) !important;
    backGround: -moz-linear-gradient(0deg, rgba(98,164,196,1) 0%, rgba(94,174,194,1) 25%, rgba(255,255,255,1) 100%)!important;
    backGround: -webkit-linear-gradient(0deg, rgba(98,164,196,1) 0%, rgba(94,174,194,1) 25%, rgba(255,255,255,1) 100%)!important;
    backGround: linear-gradient(0deg, rgba(98,164,196,1) 0%, rgba(94,174,194,1) 25%, rgba(255,255,255,1) 100%)!important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#62a4c4",endColorstr="#ffffff",GradientType=1)!important;
  };

@media screen and (min-width: 1300px) {

    .rs-modal-dialog {
        width: calc(92vw + 10px);
        margin-left: calc(-24vw + 10px);
    }

}



@media screen and (max-width: 1400px) {

    .rs-modal-dialog {
        position: relative;
        left: -2em;
        right: 1em!important;
        width: calc(91vw - 50px);
        margin-left: calc(-20vw + 50px);
    }

}

  

  @media screen and (max-width: 1024px) {

    div.rs-modal-content {
        
            left: calc(8vw + 50px)!important;
            max-width: calc(75vw + 20px)!important;
            min-width: 80vw;
        
    }

    .rs-modal-dialog {
        width: calc(80vw + 100px);
        margin-left: calc(-17vw + 25px);
    }
  }
  
  @media screen and (max-width: 780px) {


    .carousel-grid {
        display: inline-flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        align-content: center;
        min-width: 80vw;
    }

    div.grid-left {
        min-width: 90vw;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
        margin: auto;
        padding-bottom: 20px;
    }

    div.rs-modal-content {
        left: calc(0vw + 50px)!important;
    }

    .rs-modal-dialog {
        margin-left: calc(-8vw + 75px);
        width: calc(93vw - 100px);
    }
    

  }

  @media screen and (max-width: 480px) {

    .carousel-grid {
        display: inline-flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        align-content: center;
        min-width: 80vw;
    }

    div.grid-left {
        min-width: 90vw;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
        margin: auto;
        padding-bottom: 20px;
    }

    div.rs-modal-content {

        left: 0px!important

    }

    .rs-modal-content {

        max-width: 90vw;
margin-left: 70px!important;
    }

    .rs-modal-dialog {
        margin-left: calc(-2vw + 25px);
        width: 90vw;
    }

  }

  div.ui.breadcrumb {

    padding: 8px 8px!important;
    list-style: none;
    background-color: #73d2ea;
    margin-bottom: -1rem!important;
    justify-content: flex-start;
    
}

.breadcrumb {

position: relative;
display: flex;
color: black;

} 

.ui.breadcrumb .section.active {
    font-weight: 700;
    color: black;
}

.activities{

text-align: center!important;

}