/* .main-header {

    min-height: 25vh;

  } */

  .body {
    position: absolute!important;
    left: 0px !important;
    width: 97vw !important;
}

 

  .carousel-inner {
    position: relative;
    width: auto !important;
    overflow: hidden;
}

  .Carousel-root-1 {
    overflow: hidden;
    position: relative;
    top: 2.5vh;
    min-height: 31vh;
}

.navBarItems {

width: auto;

}

.row { 
  
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  
}
.logo {
  
    display: inline;
    position: relative;
    min-height: 10vh;

}

.headerarousel {

  position: relative;
  
}

.card-header {
  padding: .5rem 1rem;
  margin-bottom: 0;
  background-color: #8fc1e31f;
  border-bottom: 1px solid rgba(0,0,0,.08);
}



 