

.ui.grid {
    margin-top: -1rem;
    margin-bottom: -1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    justify-content: center;

}

.ui.blue.card, .ui.blue.cards>.card, .ui.cards>.blue.card {
    box-shadow: 0 0 0 1px #d4d4d5,0 2px 0 0 #2185d0,0 1px 3px 0 #d4d4d5;
    width: 200px!important;
    height: 420px!important;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.ui.card>.image>img, .ui.cards>.card>.image>img {
    display: block;
    width: 100%;
    height: 250px;
    border-radius: inherit;
}

div.ui.centered.cards {

        display: flex;
        align-items: center;
        flex-wrap: wrap;
        align-content: space-between;

}

.centered {
    color: rgb(0, 0, 0);
    max-width: 100%;
}

div.header.clear {


    background-color: white;
}

div.meta {
    
    color: rgba(0, 0, 0, 0.9) !important;
}

div.ui.breadcrumb {

    padding: 8px 8px!important;
    list-style: none;
    background-color: #73d2ea;
    /* margin-bottom: -1rem!important; */
    justify-content: flex-start;
    
}

.breadcrumb {
    position: relative;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
}

.ui.breadcrumb .active.section {
    font-weight: 700;
    color: black;
}

.imageStyle  {

    width : 200px !important;
    
      }