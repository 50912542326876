.MuiPaper-outlined {
    
    margin-left: 5vw;
}

.navbar {
    position: relative;
    padding-top: .5rem;
    padding-bottom: .5rem;
    background-color: #73d2ea !important;
    box-shadow: 2px 2px #88888815;
    width: 100%!important;
    display: inline-grid;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: box-shadow .15s ease-in-out;
    display: block;
    position: relative;
    left: -35vw;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    position: relative;
    /* margin-left: 45px; */
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
}

.nav-link {
    transition: none;
    margin-left: 2vw;
    position: relative;
    /* right: -5vw; */
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0,0,0,.55);
    font-weight: 600;
    font-size: calc(10px + .4rem);
    /* left: 80px; */
}

.navbar-light .navbar-toggler {
    color: rgba(0,0,0,.55);
    border-color: rgba(0,0,0,.1);
    margin-left: 1em;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 90vw;
    
}

@media screen and (max-width: 350px) {

   
div.container.accordion, div.container.accordion-lg, div.container.accordion-md, div.container.accordion-sm, div.container.accordion-xl {
    max-width: 90vw;
    margin-top: 5em;
}

}
