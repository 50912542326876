.gallery-container {
    min-width: 80vw;
    min-height: 28vh;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
    flex-direction: row;
}

.react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 70vw;
    right: -20vw;
    
}

.Carousel-root-1 {
    right: -3vw;
    max-height: 30vh;
    max-width: 68vw;
    margin-bottom: 20px;
    margin-right: 20px;
}

.img {
    
    position: relative;
   
}
.capt {

    
    font-size: min(max(16px, 4vw), 20px);
}

.banner {
width: 100vw !important; 
font-size: 1.2rem;    
}

div.card-body {

   background-color: #bddaff47;
   min-width: 24vw;
}



.carousel-control-next-icon,
.carousel-control-prev-icon /*, .carousel-indicators */ {
    filter: invert(100%);
    margin: 1em;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    margin-right: 2em !important;
}

.carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    
}



/* .carouselText {

color: #000000;
text-align: start;
font-size: 1.25rem;
font-weight: 500;

} */

.carouselText {
    color: #000000;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 500;
}


@media screen and (max-width: 780px) {

    .carouselText {
        color: #000000;
        text-align: center;
        font-size: 1.25rem;
        font-weight: 500;
    }
    
  }

@media screen and (max-width: 660px) {
    .card-body {
        flex: 1 1 auto;
        padding: 1rem 1rem;
        width: 50vw !important;
        margin-left: -70%;
    }
  }

