.frame {
	position: relative !important;
	background-color: #b9d5ff29;
    text-align: justify !important;
}

.tl-radius {
	border-top-left-radius: 15%;    
}
.fl-padding {

    padding-top: 2em !important;
    padding-left: 1em !important;
    padding-bottom: 2em !important;
    padding-right: 3em !important;
}

.tr-radius {
	border-top-right-radius: 15%;    
}

.fr-padding {
    padding-top: 2em !important;
    padding-left: 3em !important;
    padding-bottom: 2em !important;
    padding-right: 1em !important;
}

.bl-radius {
	border-bottom-left-radius: 15%;

}
.ft-padding {
    padding-top: 1em !important;
    padding-left: 2em !important;
    padding-bottom: 3em !important;
    padding-right: 2em !important;
}
.br-radius {
	border-bottom-right-radius: 15%;
}
.fb-padding {
    padding-top: 3em !important;
    padding-left: 2em !important;
    padding-bottom: 1em !important;
    padding-right: 2em !important;
}


