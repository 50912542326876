.center {

    text-align: center;
}

p {
    margin: 0 0 1em;
    line-height: 1.5em;
    font-size: 1.5em;
    font-weight: 500 !important;
}

img.logo.ui.fluid.image {

max-width: 38%;
margin: auto !important;

}

h2.center{

    background-color: #00000000;
}




.vacancies {

    background: rgb(98,164,196);
    background: -moz-linear-gradient(0deg, rgba(98,164,196,1) 0%, rgba(94,174,194,1) 25%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(98,164,196,1) 0%, rgba(94,174,194,1) 25%, rgba(255,255,255,1) 100%);
    background: linear-gradient(180deg, rgb(37 140 191) 0%, #089fb7 25%, rgb(255 255 255) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#62a4c4",endColorstr="#ffffff",GradientType=1);

}

.ui.container {
    
    max-width: calc(95% - 20px)!important;
}

@media only screen and (min-width: 300px) and (max-width: 467px){
.ui.justified.container.vacancies {
    margin-left: calc(2.5em - 20px)!important;
}
}

 @media only screen and (min-width: 468px) and (max-width: 767px) {
.ui.container {
    width: auto!important;
    margin-left: calc(3.5em - 38px)!important;
    margin-right: 1em!important;
} 

}

@media only screen and (min-width: 992px) and (max-width: 1199px)  {
.ui.container {
    width: auto!important;
    margin-left: 2.4em!important;
    margin-right: 2.4em!important;
}
}

