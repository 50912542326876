

body {
font-family: 'Noto Serif';
overflow-x: hidden;
background-color: #73d2ea;

}

.App {
  text-align: center;
  margin-top: 5vh;
}





.App-content {
  
  min-height: 120vh;
  display: flex;
  justify-content: center;
  color: white;
  padding-top: 2vh;
  flex-direction: column;
text-align: center;



background: rgb(58,88,99);
background: -moz-linear-gradient(0deg, rgba(58,88,99,1) 0%, rgba(45,124,143,1) 20%, rgba(116,211,235,1) 100%);
background: -webkit-linear-gradient(0deg, rgba(58,88,99,1) 0%, rgba(45,124,143,1) 20%, rgba(116,211,235,1) 100%);
background: linear-gradient(0deg, rgba(58,88,99,1) 0%, rgba(45,124,143,1) 20%, rgba(116,211,235,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3a5863",endColorstr="#74d3eb",GradientType=1);
}

.App-link {
  color: #61dafb;
}



